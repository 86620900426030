@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}



/*@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');


body {
  font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bb {
    border: 1px solid red;
}

.bb2 {
  border: 5px solid green;
}

.bb3 {
  border: 5px solid purple;
}

.scroll-snap {
  scroll-snap-type: x mandatory;
}

.test {
  animation: fadeInn 1s forwards;
}

@keyframes fadeInn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.second-ball {
  animation-delay: 10s;
}
